import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ongoing:[],
  // past:[]
}

const bookingListSlice = createSlice({
  name: 'booking-list',
  initialState,
  reducers: {
    setOngoingList(state, { payload }) {
      state.ongoing = payload;
    },
    // setPastList(state, { payload }) {
    //   state.past = payload
    // },
  }
});

// export const { setOngoingList, setPastList } = bookingListSlice.actions;
export const { setOngoingList } = bookingListSlice.actions;

export default bookingListSlice.reducer;
