import BookingCard from '../Cards/BookingCard.component';
import { BOOKINGS_DATA } from '../../data/Bookings.data';

const PastBookings = () => (
  <div className='flex flex-wrap gap-5'>
    {BOOKINGS_DATA.map(item => (
      <BookingCard 
        key={`Past_${item.id}`} 
        type="past"
        data={item}
      />
    ))}
  </div>
);

export default PastBookings;