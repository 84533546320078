import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import { OTP_BANNER, BACK_ARROW, LOGO_TEXT } from '../../assets/Images';
import AuthContext from '../../utils/AuthContext';
import { useSelector } from 'react-redux';
import { axios_instance } from '../../utils/axios.utils';
import { setCookie } from '../../utils/Cookie';

const OTP = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const { setAuthStatus } = useContext(AuthContext);
  const phoneNum = useSelector((state) => state.phoneNum)
  const [isLoading, setIsLoading] = useState(false)
  const [isIncorrectOtp, setIsIncorrectOtp] = useState(false)

  const otpChange=(e)=>{
    isIncorrectOtp && setIsIncorrectOtp(false)
    setOtp(e.target.value)
  }

  //Verifying otp
  const otpVerify = async () => {
    setIsLoading(true)
    await axios_instance.post('service-provider/verify-otp',{
      mobileno: phoneNum.mobileno,
      country_code_id: phoneNum.country_code_id,
      otp: otp
    }).then(res => {
      if(res.data.status==='ok'){
        setCookie('@authToken', res.data.token);
        if(res.data?.qb_token) {
          setCookie('@QBToken', res.data.qb_token);
        }
        setAuthStatus('loggedIn');
      }else{
        setIsLoading(false)
        setIsIncorrectOtp(true)
      }
    }).catch(()=>{
      setIsLoading(false)
      setIsIncorrectOtp(true)
    })
  }

  return (
    <div className='bg-white min-h-[100vh] flex'>

      {/* Otp side */}
      <div className='w-[40%] p-10'>

        {/* Logo */}
        <img src={LOGO_TEXT.default} alt='' />

        {/* Back arrow */}
        <img 
          src={BACK_ARROW.default} 
          alt='' 
          className='w-6 h-6 mt-[130px] hover:cursor-pointer hover:opacity-70' 
          onClick={() => navigate(-1)}  
        />

        {/* Title */}
        <p className='text-[46px] text-primary_color font-normal mt-5'>Enter OTP</p>

        <p className='text-gray_scale_2 text-[12px] font-light mb-8'>We've sent an OTP to your phone number.</p>

        {/* Otp input */}
        <input 
          value={otp} 
          onChange={otpChange}
          placeholder="Enter OTP" 
          className='outline-0 w-[400px] h-[52px] bg-near_white px-[12px] rounded-lg text-black_scale_1 font-normal text-[16px]'  
          maxLength={4}
        />

        {/* Verify Otp */}
        <CustomButton 
          title="Verify" 
          onClick={otpVerify} 
          disabled={otp?.trim('')?.length < 4} 
          buttonClassNames='!mt-4'
          isLoading={isLoading}
        />

        {/* Agreement */}
        <p className='max-w-[349px] mt-[15px] mb-[30px] font-light text-[12px] text-gray_scale_2'>
          By clicking on Login, I accept the&nbsp;
          <span className='text-primary_color underline'>Terms & Conditions</span> &&nbsp; 
          <span className='text-primary_color underline'>Privacy Policy</span>
        </p>

        {/* otp incorrect message */}
        {isIncorrectOtp && (
          <div className="w-[400px] h-[52px] rounded-lg bg-light_red text-red flex justify-center items-center" role="alert">
            <span className="">OTP Incorrect. Try again</span>
          </div>
        )}
      </div>

      {/* Banner */}
      <div className='h-full min-h-[100vh] w-[60%] bg-ver_light_blue flex items-center justify-center'>
        <img src={OTP_BANNER} alt='' className='h-[450px]' />
      </div>

    </div>
  );
}

export default OTP;