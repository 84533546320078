import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as QB from 'quickblox/quickblox';
import { ToastContainer, toast } from 'react-toastify';
import Login from './pages/Login/Login.page';
import OTP from './pages/OTP/OTP.page';
import Home from './pages/Home/Home.page';
import VideoCall from './pages/VideoCall/VideoCall.page';
import Chat from './pages/Chat/Chat.page';
import Navbar from './components/Navbar/Navbar.component';
import Footer from './components/Footer/Footer.component';
import AuthContext from './utils/AuthContext';
import { setCallNotification, setChatConnected } from './redux/chatSlice';
import { axios_instance } from './utils/axios.utils';
import { getCookie, setCookie } from './utils/Cookie';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const CONFIG = {
  debug: { mode: 0 },
  webrtc: {
    answerTimeInterval: 60,
    autoReject: true,
    incomingLimit: 1,
    dialingTimeInterval: 5,
    disconnectTimeInterval: 30,
    statsReportTimeInterval: false
 }
};

//Initializing Quickblox
QB.init(
  process.env.REACT_APP_QUICKBLOX_APP_ID,
  process.env.REACT_APP_QUICKBLOX_AUTH_KEY,
  process.env.REACT_APP_QUICKBLOX_AUTH_SECRET,
  process.env.REACT_APP_QUICKBLOX_ACCOUNT_KEY,
  CONFIG
);

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const callNotification = useSelector(state => state.chat.callNotification);
  const [authStatus, setAuthStatus] = useState('loggedOut');

  //Connecting to chat
  const connectToChat = (session) => {
    const userCredentials = {
      userId: session.user_id,
      password: session.token
    };

    QB.chat.connect(userCredentials, (error, contactList) => {
      if(error) {
        //Error
      } else {
        dispatch(setChatConnected(true));
      }
    });
  }

  // Refresh QB Session
  const refreshQBSession = (sessionToken) => {
    if(sessionToken) {
      setCookie('@QBToken', sessionToken);
      QB.startSessionWithToken(sessionToken, async(err, session) => {
        if (err){
          // Error
        } else {
          localStorage.setItem("@QBChatUser", JSON.stringify(session.session));
          connectToChat(session.session);
        }
      });
    } else {
      toast.error('Chat token refresh failed');
    }
  }

  // Refresh quickblox
  const refreshQuickblox = () => {
    const authToken = getCookie('@authToken');
    axios_instance.get('service-provider/refresh-qblox', {
      headers: { Authorization: `Bearer ${authToken}` }
    }).then((res) => {
      if(res.data.status === 'ok' && res.data?.data?.qbToken) {
        refreshQBSession(res.data?.data?.qbToken);
      } else {
        toast.error('Chat token refresh failed');
      }
    }).catch((err) => {
      toast.error('Chat token refresh failed');
    });
  }

  // Set QB session
  const setQBSession = async() => {
    let sessionToken = getCookie('@QBToken');
    if(sessionToken) {
      QB.startSessionWithToken(sessionToken, async(err, session) => {
        if (err){
          // Error
          // Refresh quickblox api
          refreshQuickblox();
        } else {
          localStorage.setItem("@QBChatUser", JSON.stringify(session.session));
          connectToChat(session.session);
        }
      });
    } else {
      // Refresh quickblox api
      refreshQuickblox();
    }
  }

  //Closing video call
  const closeVideo = () => {
    callNotification.session?.stop({});
    navigate(-1);
    dispatch(setCallNotification({ show: false, session: null, type: null }));
  }

  const getMediaStream = (makeCall) => {
    //Getting media stream
    let mediaParams = {
      audio: true,
      video: true,
      options: {
        muted: false,
        mirror: true,
      },
      elemId: "videoCallOurVideo",
    };
    
    callNotification.session.getUserMedia(mediaParams, (error, stream) => {
      if (error) {
        //Error in getting stream
      } else {
        callNotification.session.attachMediaStream("videoCallOurVideo", stream);
        if(makeCall) {
          //Making call
          callNotification.session.call({}, (error) => {
            //Error in making call
          });
        } else {
          callNotification.session.accept();
        }
      }
    });
  }

  // Session expiration check
  QB.chat.onSessionExpiredListener = (error) => {
    if (error) {
      // Error
    } else{
      // Refresh quickblox api
      const authToken = getCookie('@authToken');
      axios_instance.get('service-provider/refresh-qblox', {
        headers: { Authorization: `Bearer ${authToken}` }
      }).then((res) => {
        if(res.data.status === 'ok' && res.data?.data?.qbToken) {
          refreshQBSession(res.data?.data?.qbToken);
        }
      }).catch((err) => {});
    }
  }

  //On incoming call
  QB.webrtc.onCallListener = (session, extension) => { 
    dispatch(setCallNotification({ show: true, session, type: 'INCOMING' }));
  }

  //Opponent video stream listener
  QB.webrtc.onRemoteStreamListener = (session, userId, remoteStream) => {
    // attach the remote stream to DOM element
    session.attachMediaStream("remoteOpponentVideoElement", remoteStream);
  }

  //On call gets rejected by opponent
  QB.webrtc.onRejectCallListener = (session, userId, extension) => {
    closeVideo();
  }

  //on stopping call
  QB.webrtc.onStopCallListener = (session, userId, extension) => { 
    closeVideo();
  }

  //On opponent not answered
  QB.webrtc.onUserNotAnswerListener = (session, userId) => {
    closeVideo();
  }

  //On opponent accepting call
  QB.webrtc.onAcceptCallListener = (session, userId, extension) => {
    
  }

  //Checking authentication
  const checkAuth = () => {
    let token = getCookie('@authToken');
    if(token) {
      setAuthStatus('loggedIn');
    } else {
      setAuthStatus('loggedOut');
    }
  }

  useEffect(() => {
    if(authStatus === 'loggedIn') {
      // Setting QB session
      setQBSession();
    }
    // eslint-disable-next-line
  }, [authStatus]);

  useEffect(() => {
    //Checking authentication
    checkAuth();

    return () => {
      //Disconnecting from chat
      QB.chat.disconnect();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(callNotification.type === 'OUTGOING') {
      getMediaStream(true);
    }
    // eslint-disable-next-line
  }, [callNotification.type]);

  //Accept call
  const acceptCall = () => {
    dispatch(setCallNotification({ ...callNotification, show: false }));
    navigate('/video_call');
    getMediaStream(false);
  }

  //Reject call
  const rejectCall = () => {
    callNotification.session.reject({});
    closeVideo();
  }

  return (
    <AuthContext.Provider value={{ authStatus, setAuthStatus }}>
      <div className="App">

        {/* Navbar */}
        {authStatus === 'loggedIn' && <Navbar />}

        {/* Routes */}
        <Routes>
          <Route path='/' element={authStatus === 'loggedIn' ? <Home /> : <Navigate to='/login' />} />
          <Route path='/chat' element={authStatus === 'loggedIn' ? <Chat /> : <Navigate to='/login' />} />
          <Route path='/video_call' element={authStatus === 'loggedIn' ? <VideoCall /> : <Navigate to='/login' />} />
          <Route path='/login' element={authStatus === 'loggedIn' ? <Navigate to='/' /> : <Login />} />
          <Route path='/otp' element={authStatus === 'loggedIn' ? <Navigate to='/' /> : <OTP />} />
        </Routes>

        {/* Footer */}
        {authStatus === 'loggedIn' && <Footer />}

        {/* Call notification */}
        {callNotification.show && (
          <div className='p-5 shadow-gray_shadow absolute top-5 right-10'>
            <p>Incoming call...</p>
            <div className='flex items-center mt-5'>
              <div 
                className='bg-primary_color flex justify-center items-center rounded-xl px-5 py-3 mr-5 hover:cursor-pointer hover:opacity-70' 
                onClick={acceptCall}
              >
                <p className='text-white text-[16px] font-medium'>Accept</p>
              </div>
              <div 
                className='bg-dark_red flex justify-center items-center rounded-xl px-5 py-3 hover:cursor-pointer hover:opacity-70' 
                onClick={rejectCall}
              >
                <p className='text-white text-[16px] font-medium'>Reject</p>
              </div>
            </div>
          </div>
        )}

      </div>

      {/* Toast */}
      <ToastContainer position='top-right' autoClose={5000} theme='dark' />

    </AuthContext.Provider>
  );
}

export default App;
