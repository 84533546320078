import { configureStore, combineReducers } from '@reduxjs/toolkit';
import bookingListSlice from './bookingListSlice';
import chatSlice from './chatSlice';
import phoneNumSlice from './phoneNumSlice';

const combinedReducer = combineReducers({
  chat: chatSlice,
  phoneNum:phoneNumSlice,
  bookingList:bookingListSlice
});

const rootReducer = (state, action) => {
  if(action.type === "RESET") {
    state = undefined;
  }
  return combinedReducer(state, action);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => (
    getDefaultMiddleware({
      serializableCheck: false
    })
  )
});

export default store;