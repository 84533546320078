import { USER } from '../../assets/Images';
import CustomButton from '../CustomButton/CustomButton.component';

const BookingCard = ({ data, type, onChatClick, containerClassNames, handleConfirmModalOpen_chat, handleCancelModalOpen_chat }) => {
  let imageUrl = data?.customer?.userprofile?.image;
  let dob_res = data?.customer?.userprofile?.dob;

  const ageCalculator = (dob) => {
    let dob_dt = new Date(dob);
    let month_diff = Date.now() - dob_dt.getTime();
    let age_dt = new Date(month_diff); 
    let age = Math.abs(age_dt.getUTCFullYear() - 1970);

    return age;
  }

  return(
  <div className={`w-[calc((100%-50px)/2)] border border-gray_white rounded-lg p-5 space-y-1.5 ${containerClassNames}`}>
    <div className='flex items-center justify-between'>
      <div className='flex items-center'>

        {/* User profile pic */}
        <img src={imageUrl ? `${process.env.REACT_APP_BASE_URL + imageUrl}` : USER} alt='' className='w-[49px] h-[44px] rounded-[50%]' />

        <div className='ml-3'>
          <p className='font-semibold text-black text-[14px]'>{data?.customer?.userprofile?.name}</p>
          <p className='text-black font-light text-[12px]'>Age: {dob_res ? ageCalculator(dob_res).toString() : '-'}</p>
        </div>
      </div>

      {/* Chat button */}
      {type === 'ongoing' ? (
        <div 
          className='bg-primary_color h-[35px] flex items-center justify-center px-5 rounded-lg hover:cursor-pointer hover:opacity-80'
          onClick={() => onChatClick(data)}  
        >
          <p className='text-white font-semibold text-[14px]'>Chat With Patient</p>
        </div>
      ) : null}

    </div>

    {/* Description */}
    <p className='text-light_black text-[14px] font-normal'>{data?.description}</p>

    <div className='flex gap-5'>
      {/* <p className='text-[14px] text-near_black font-light'>Motion: {data.motion}</p>
      <p className='text-[14px] text-near_black font-light'>Appetite: {data.appetite}</p> */}
      <p className='text-[14px] text-near_black font-light'>Date: {data?.datetime}</p>
    </div>

    {/* booking btns */}
    {type === 'chat' ? (
      <div className='flex space-x-3 pt-1' >
        <CustomButton 
          title={data?.status === 'cancelled' ? 'Cancelled' : 'Cancel Booking'}
          buttonClassNames='!w-max !h-[35px] !px-5 !bg-dark_red'
          titleClassNames='!font-semibold !text-[14px] !text-white'
          onClick={handleCancelModalOpen_chat} 
          disabled={data?.status === 'cancelled'} 
        />
        <CustomButton 
          title={data?.status === 'scheduled' ? 'Confirmed' : 'Confirm Booking'}
          buttonClassNames='!w-max !h-[35px] !px-5 !bg-primary_color'
          titleClassNames='!font-semibold !text-[14px] !text-white'
          onClick={handleConfirmModalOpen_chat}
          disabled={data?.status === 'scheduled'} 
        />
      </div>
    ) : null}
  </div>
  )
}

export default BookingCard;