import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCallNotification } from '../../redux/chatSlice';

const VideoCall = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const callNotification = useSelector(state => state.chat.callNotification);

  useEffect(() => {
    //Going to home if no session id
    if(callNotification.session === null) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, []);

  //End call
  const endCall = () => {
    callNotification.session.stop({});
    navigate(-1);
    dispatch(setCallNotification({ show: false, session: null, type: null }));
  }

  return (
    <div className='p-20 min-h-[calc(100vh-60px)] pt-[100px] bg-smoke_white'>
      <div className='h-80vh w-75vw relative bg-black'>
        <video id="remoteOpponentVideoElement" className='h-[80vh] w-[75vw] bg-black' />
        <video id="videoCallOurVideo" className='absolute right-0 bottom-0 z-10 w-[200px] h-[200px]' /> 
      </div>
      <div 
        className='bg-dark_red flex justify-center items-center rounded-xl w-[10rem] m-auto mt-10 py-5 hover:cursor-pointer hover:opacity-70'
        onClick={endCall}
      >
        <p className='text-white text-[16px] font-medium'>End Call</p>
      </div>
    </div>
  );
}

export default VideoCall;