import Cookies from 'universal-cookie';
 
const cookies = new Cookies();

// Set cookie
export const setCookie = (key, value) => {
  cookies.set(key, value, { path: '/' });
}

// Get cookie
export const getCookie = (key) => {
  return cookies.get(key);
}

// Delete cookie
export const deleteCookie = (key) => {
  cookies.remove(key, { path: '/' });
}