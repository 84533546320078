import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import { LOGIN_BANNER, LOGO_TEXT } from '../../assets/Images';
import { mobileNumberValidation } from '../../utils/Validations';
import { Menu, Transition } from '@headlessui/react';
import { axios_instance } from '../../utils/axios.utils';
import { setPhoneNum } from '../../redux/phoneNumSlice';
import { useDispatch } from 'react-redux';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobNoErr, setMobNoErr] = useState(null);
  const [countryCodes, setCountryCodes] = useState([])
  const [selectedCountry, setSelectedCountry] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  //Mobile number input value change
  const mobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
    setMobNoErr(mobileNumberValidation(e.target.value));
  }

  //send otp then Go to otp page
  const goToOtpPage = async () => {
    setIsLoading(true)
    
    await axios_instance.post('service-provider/get-started', {
      mobileno: mobileNumber,
      country_code_id: selectedCountry.id
    }).then((res) => {
      if(res.data.status==='ok') {
        dispatch(setPhoneNum({ mobileno: mobileNumber, country_code_id: selectedCountry.id }));
        navigate('/otp')
      }else{
        toast.error(res?.data?.message);
        setIsLoading(false)
      }
    }).catch((err) => {
      setIsLoading(false)
    });
  }

  useEffect(()=>{
    axios_instance.get('listAllCountryCodes')
      .then(res => {
        setCountryCodes(res.data.data)
        setSelectedCountry(res.data.data[0])
      })
  },[])

  return (
    <div className='bg-white min-h-[100vh] flex'>
      
      {/* Login Side */}
      <div className='w-[40%] p-10'>

        {/* Logo */}
        <img src={LOGO_TEXT.default} alt='' />

        {/* Title */}
        <p className='text-[46px] text-primary_color font-normal mt-[150px] mb-8'>Get Started</p>

        {/* phone number input */}
        {/* <div className='flex flex-row items-center h-[52px] w-[400px] bg-near_white rounded-lg overflow-hidden px-2.5'> */}
        <div className='flex flex-row items-center h-[52px] w-[400px] bg-near_white rounded-lg px-2.5'>
          {/* <p className='border-r border-r-light_gray pr-2.5 text-black_scale_1 font-normal text-[16px]'>
            +91
          </p> */}
          {/* dropdown-menu */}
          <Menu as="div" className="relative inline-block text-left
            border-r border-r-light_gray pr-2.5 text-black_scale_1 font-normal text-[16px]
          ">
            <div>
              <Menu.Button className="">
                {`+${selectedCountry.country_code ?? '91'}`}
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {countryCodes.map((item) => (
                    <Menu.Item key={item.id} >
                      <p
                        className='bg-gray-100 text-gray-900 block px-2 py-2 text-sm cursor-pointer hover:bg-gray_smoke_white'
                        onClick={()=>setSelectedCountry(item)}
                      >{`+${item.country_code}`}</p>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <input 
            value={mobileNumber} 
            onChange={mobileNumberChange} 
            placeholder="Phone Number" 
            className='outline-0 w-[320px] h-[52px] bg-near_white px-[12px] text-black_scale_1 font-normal text-[16px]'  
          />
        </div>
        <p className='text-[10px] text-red font-light h-4'>{mobNoErr}</p>

        {/* Continue Button */}
        <CustomButton 
          title="Continue" 
          onClick={goToOtpPage} 
          disabled={mobNoErr || mobileNumber?.trim('')?.length === 0} 
          buttonClassNames='!mt-3'
          isLoading={isLoading}
        />

        {/* Agreement */}
        <p className='max-w-[349px] mt-[15px] mb-[30px] font-light text-[12px] text-gray_scale_2'>
          By clicking on Login, I accept the&nbsp;
          <span className='text-primary_color underline'>Terms & Conditions</span> &&nbsp; 
          <span className='text-primary_color underline'>Privacy Policy</span>
        </p>

      </div>

      {/* Banner */}
      <div className='h-full min-h-[100vh] w-[60%] bg-ver_light_blue flex items-center justify-center'>
        <img src={LOGIN_BANNER} alt='' className='h-[450px]' />
      </div>

    </div>
  );
}

export default Login;