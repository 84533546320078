import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import BookingCard from '../Cards/BookingCard.component';
import { getCookie } from '../../utils/Cookie';

const OngoingBookings = () => {
  const navigate = useNavigate();

  const bookingData_ongoing = useSelector((state) => state.bookingList.ongoing);

  //For chatting with patient
  const chatWithPatient = (data) => {
    let sessionToken = getCookie('@QBToken');
    if(sessionToken) {
      navigate('chat', { state: { bookingData: data } });
    } else {
      toast.error('Chat account not active');
    }
  }

  return (
    <div className='flex flex-wrap gap-5'>
      {bookingData_ongoing?.map(item => (
        <BookingCard 
          key={`Ongoing_${item.id}`} 
          type="ongoing"
          onChatClick={chatWithPatient}
          data={item}
        />
      ))}
    </div>
  );
}

export default OngoingBookings;