import { useState, useEffect } from 'react';
import moment from 'moment/moment';
import * as QB from 'quickblox/quickblox';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';
import { GREEN_CHAT_CORNER, GREY_CHAT_CORNER, TICK, SQUARE_CLOCK } from '../../assets/Images';

const StyledCircularProgress = styled(CircularProgress)`
  color: white;
  position: absolute;
  z-index: 10;
  top: calc(50% - 13px);
  left: calc(50% - 13px);
`;

const MessageSnippet = ({ msg, received, nextMsg, prevMsg, messageUserName }) => {
  const [attachmentUrl, setAttachmentUrl] = useState('');

  //Getting attachmenturl
  const getAttachmentUrl = () => {
    let fileUID = msg.attachments[0].id;
    setAttachmentUrl(QB.content.privateUrl(fileUID));
  }

  useEffect(() => {
    if(msg?.attachments?.length > 0) {
      getAttachmentUrl();
    } else if(msg?.attachmentUrl) setAttachmentUrl(msg.attachmentUrl);
    // eslint-disable-next-line
  }, []);

  //Checking whether show corner in message or not
  const showCorner = () => {
    if(
      prevMsg === null ||
      (moment(msg.created_at).format("DD-MM-YYYY hh:mm A") !== moment(prevMsg.created_at).format("DD-MM-YYYY hh:mm A")) ||
      (msg.sender_id !== prevMsg.sender_id)
    ) {
      return true;
    }
    return false;
  }

  //Checking whether to show time in message or not
  const showTime = () => {
    if(
      nextMsg === null ||
      (moment(msg.created_at).format("DD-MM-YYYY hh:mm A") !== moment(nextMsg.created_at).format("DD-MM-YYYY hh:mm A")) ||
      (msg.sender_id !== nextMsg.sender_id)
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      <div
        className={`
          mb-[5px] px-2.5 pt-2.5 pb-1 rounded-b-[10px] relative w-fit max-w-[250px]
          ${received ? 'bg-gray_smoke_white self-start rounded-tr-[10px]' : 'bg-very_light_green self-end rounded-tl-[10px]'}
        `}
      >

        {/* Corner */}
        {showCorner() && (
          <img 
            src={received ? GREY_CHAT_CORNER : GREEN_CHAT_CORNER}
            alt=''
            className={`absolute w-[23px] h-5 top-[-1px] ${received ? 'left-[-5px]' : 'right-[-5px]'}`}
          />
        )}

        {(msg?.attachments?.length > 0 || msg?.attachmentUrl) && (

          //Image messages
          msg?.messageType === "image" ? (
            <div className='w-[150px] h-[150px] relative'>
              <img 
                src={attachmentUrl} 
                className='w-[150px] h-[150px] hover:cursor-pointer' 
                alt='' 
                style={msg.status === 'uploading' ? { filter: 'blur(4px)' } : {}} 
              />
              {msg.status === 'uploading' ? <StyledCircularProgress size={26} /> : null}
            </div>
          ) : (
            <div />
          )

        )}

        {/* Message text */}
        {msg?.message && (
          <p className={`font-normal text-[12px] text-near_black ${msg?.attachments?.length > 0 && 'mt-2.5'}`}>
            {msg.message}
          </p>
        )}

        {/* Status */}
        {(msg?.status === 'uploading' || msg?.status === 'sending') ? (
          <img src={SQUARE_CLOCK} alt='' className='w-2.5 h-2.5 float-right mt-[3px]' />
        ) : (
          <img src={TICK} alt='' className='w-3 h-4 float-right mt-[3px]' />
        )}

      </div>

      {/* Time */}
      {showTime() && (
        <p className={`font-normal text-[10px] text-near_gray mb-3 ${received ? 'ml-[5px]' : 'text-right mr-[5px]'}`}>
          {moment(msg.created_at).format("h:mmA")}
        </p>
      )}

    </>
  );
}

export default MessageSnippet;