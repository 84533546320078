import { useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import OngoingBookings from './OngoingBookings.component';
import PastBookings from './PastBookings.component';
import { 
  SHOPPING_BAG_BLACK, 
  SHOPPING_BAG_GREEN, 
  // RELOAD_BLACK, 
  // RELOAD_GREEN 
} from '../../assets/Images';

const StyledTabList = styled(TabList)`
  .MuiTabs-indicator {
    background-color: #2F9D5B !important;
    height: 4px !important;
  }
`;

const Bookings = () => {
  const [selectedTab, setSelectedTab] = useState('ongoing');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }

  return (
    <div className='bg-white p-[30px] rounded-xl'>
      <TabContext value={selectedTab}>
        <StyledTabList onChange={handleTabChange}>

          {/* Ongoing bookings tab head */}
          <Tab
            value="ongoing" 
            color={selectedTab === "ongoing" ? '#2F9D5B' : '#232323'}
            icon={
              <div className='flex items-center'>
                <div className='w-[24px] h-[24px]'>
                  <img
                    src={selectedTab === 'ongoing' ? SHOPPING_BAG_GREEN.default : SHOPPING_BAG_BLACK.default}
                    alt="" 
                  />
                </div>
                <p 
                  className={`
                    ml-[10px] capitalize font-bold text-[18px] ${selectedTab === 'ongoing' ? 'text-primary_color' : 'text-near_black'}
                  `}
                >
                  Ongoing
                </p>
              </div>
            }
            className='capitalize'
          />

          {/* Past bookings tab head */}
          {/* <Tab 
            value="past" 
            color={selectedTab === "past" ? '#2F9D5B' : '#232323'}
            icon={
              <div className='flex items-center'>
                <div className='w-[24px] h-[24px]'>
                  <img
                    src={selectedTab === 'past' ? RELOAD_GREEN.default : RELOAD_BLACK.default}
                    alt="" 
                  />
                </div>
                <p 
                  className={`
                    ml-[10px] capitalize font-bold text-[18px] ${selectedTab === 'past' ? 'text-primary_color' : 'text-near_black'}
                  `}
                >
                  Past
                </p>
              </div>
            }
            className='capitalize !ml-[20px]'
          /> */}

        </StyledTabList>

        {/* My orders tab panel */}
        <TabPanel value="ongoing">
          <OngoingBookings />
        </TabPanel>

        {/* Past orders tab panel */}
        <TabPanel value="past">
          <PastBookings />
        </TabPanel>

      </TabContext>
    </div>
  );
}

export default Bookings;