// Login
export const LOGIN_BANNER = require('./login_banner.png');

//OTP
export const OTP_BANNER = require('./otp_banner.png');
export const BACK_ARROW = require('./back_arrow.svg');

//Navbar
export const LOGO_TEXT = require('./logo_text.svg');
export const LOGOUT = require('./logout.svg');

//Home
export const RELOAD_BLACK = require('./reload_black.svg');
export const RELOAD_GREEN = require('./reload_green.svg');
export const SHOPPING_BAG_BLACK = require('./shopping_bag_black.svg');
export const SHOPPING_BAG_GREEN = require('./shopping_bag_green.svg');

// Chat
export const GREEN_PHONE = require('./green_phone.png');
export const VIDEO = require('./video.png');
export const SEND = require('./send.png');
export const EMOJI = require('./emoji.png');
export const PIN = require('./pin.png');
export const MIC = require('./mic.png');
export const GREEN_CHAT_CORNER = require('./green_chat_corner.png');
export const GREY_CHAT_CORNER = require('./grey_chat_corner.png');
export const GALLERY = require('./gallery.png');
export const DOCUMENT = require('./document.png');
export const CAMERA = require('./camera.png');
export const CLOSE = require('./close.png');
export const KEYBOARD = require('./keyboard.png');
export const USER = require('./user.png');
export const CHAT_BANNER = require('./chat_banner.png');
export const SQUARE_CLOCK = require('./square_clock.png');
export const TICK = require('./tick.png');