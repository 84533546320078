import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mobileno: null,
  country_code_id: null
}

const phoneNumSlice = createSlice({
  name: 'phone-num',
  initialState,
  reducers: {
    setPhoneNum(state, { payload }) {
      state.mobileno = payload.mobileno;
      state.country_code_id = payload.country_code_id;
    },
  }
});

export const { setPhoneNum } = phoneNumSlice.actions;

export default phoneNumSlice.reducer;