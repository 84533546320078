import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as QB from 'quickblox/quickblox';
import moment from 'moment/moment';
import CircularProgress from '@mui/material/CircularProgress';
import MessageSnippet from '../MessageSnippet/MessageSnippet.component';
import {
  USER,
  VIDEO,
  // GREEN_PHONE,
  SEND,
  PIN
} from '../../assets/Images';
import { getHeightAndWidthFromDataUrl, generateRandomId } from '../../utils/CustomFunctions';
import { setCallNotification } from '../../redux/chatSlice';

const ChatWindow = ({ chatWindow, setChatWindow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [showMessages, setShowMessages] = useState(false);
  const [msg, setMsg] = useState('');
  const msgEndRef = useRef(null);
  const fileInputRef = useRef(null);
  let today = moment();
  let yesterday = moment().add(-1, 'days');
  const msgShowTimeout = useRef(null);
  const scrollTimeout = useRef(null);

  // Scroll to bottom of message
  const scrollToBottom = () => {
    msgEndRef.current?.scrollIntoView({ behavior: "smooth" });
    if(!showMessages) {
      msgShowTimeout.current = setTimeout(() => {
        setShowMessages(true);
      }, 1000);
    }
  }

  useEffect(() => {
    if(messages?.length > 0) {
      scrollTimeout.current = setTimeout(() => {
        scrollToBottom();
      }, 200);
    }
    // eslint-disable-next-line
  }, [messages]);

  // Load chat history
  const retrieveChatHistory = () => {
    var params = {
      chat_dialog_id: chatWindow.dialog._id,
      sort_asc: 'created_at'
    };
    
    QB.chat.message.list(params, (error, messages) => {
      if(error) {
        //Error
        setShowMessages(true);
      } else {
        if(messages.items?.length > 0) setMessages([...messages.items]);
        else setShowMessages(true);
      }
    });
  }

  // Load last message
  const loadLastMessage = () => {
    var params = {
      chat_dialog_id: chatWindow.dialog._id,
      sort_desc: 'created_at',
      limit: 1
    };
    
    QB.chat.message.list(params, (error, messages) => {
      if(error) {
        //Error
        if(!showMessages) {
          setShowMessages(true);
        }
      } else {
        if(messages?.items[0]?.sender_id === chatWindow.userId) {
          setMessages(msgs => msgs.map(item => {
            if(item?.id === messages.items[0]?.msgId) return messages.items[0];
            else return item;
          }));
        } else {
          setMessages(msgs => [...msgs, ...messages.items]);
        }
      }
    });
  }

  //When new message arrives
  QB.chat.onMessageListener = (userId, message) => {
    loadLastMessage();
  }

  useEffect(() => {
    retrieveChatHistory();

    return () => {
      if(msgShowTimeout.current) clearTimeout(msgShowTimeout.current);
      if(scrollTimeout.current) clearTimeout(scrollTimeout.current);
    }
    // eslint-disable-next-line
  }, []);

  //Initiating video call
  const initiateVideoCall = () => {
    //Creating video call session
    let calleesIds = [chatWindow.opponentId];
    let sessionType = QB.webrtc.CallType.VIDEO;
    let session = QB.webrtc.createNewSession(calleesIds, sessionType, null, {});
    dispatch(setCallNotification({ show: false,  session, type: 'OUTGOING' }));
    navigate("/video_call");
  }

  //Send message
  const sendMessage = (messageRequest) => {
    if(msg?.trim('')?.length > 0 || messageRequest?.extension?.dialog_id) {
      // Setting temp msg
      let tempId = generateRandomId(10);
      if(!messageRequest?.extension?.dialog_id) {
        let msgItem = {
          status: 'sending',
          chat_dialog_id: chatWindow.dialog._id,
          receipient_id: chatWindow.opponentId,
          sender_id: chatWindow.userId,
          created_at: new Date(),
          id: tempId,
          message: msg
        }
        setMessages(msg => [...msg, msgItem]);
      }

      let message = {
        type: "chat",
        body: msg,
        extension: {
          save_to_history: 1,
          dialog_id: chatWindow.dialog._id,
          msgId: tempId
        },
        markable: 1
      };
      
      try {
        QB.chat.send(
          chatWindow.opponentId, 
          messageRequest?.extension?.dialog_id ? messageRequest : message
        );
        setMsg('');
        loadLastMessage();
      } catch (e) {
        if (e.name === 'ChatNotConnectedError') {
          // not connected to chat
        }
      }
    }
  }

  //Adding an image for sending to chat
  const addImage = async(e) => {
    if(e.target.files?.length > 0) {
      let file = e.target.files[0];
      let fileParams = {
        name: file.name,
        file: file,
        type: file.type,
        size: file.size,
        public: false
      };
      let nameSplitArr = file.name.split('.');
      let fileExtension = nameSplitArr[nameSplitArr.length - 1];
      if(fileExtension === 'jpeg') {
        fileExtension = 'jpg';
      }
      let imgTempUrl = URL.createObjectURL(file);
      const imgDimensions = await getHeightAndWidthFromDataUrl(imgTempUrl);

      // Setting temp item
      let tempId = generateRandomId(10);
      let msgItem = {
        status: 'uploading',
        chat_dialog_id: chatWindow.dialog._id,
        receipient_id: chatWindow.opponentId,
        sender_id: chatWindow.userId,
        attachmentUrl: imgTempUrl,
        messageType: 'image',
        fileExtension,
        fileSize: file.size,
        height: imgDimensions.height,
        width: imgDimensions.width,
        created_at: new Date(),
        id: tempId,
        message: msg.trim('')?.length > 0 ? msg : ''
      }
      setMessages(msg => [...msg, msgItem]);

      // Upload Image
      QB.content.createAndUpload(fileParams, (error, result) => {
        if (!error) {
          const message = {
            type: "chat",
            body: msg.trim('')?.length > 0 ? msg : '',
            extension: {
              save_to_history: 1,
              dialog_id: chatWindow.dialog._id,
              attachments: [{ id: result.uid, type: "image" }],
              messageType: 'image',
              fileExtension,
              fileSize: file.size,
              height: imgDimensions.height,
              width: imgDimensions.width,
              msgId: tempId
            }
          };
      
          // send the message
          sendMessage(message);
        }
      });
    } 
  }

  //Image option select
  const imageOptionSelect = () => {
    fileInputRef.current.click();
  }

  //End chat
  const endChat = () => {
    setChatWindow({ show: false, dialog: null, userId: null, opponentId: null });
    navigate(-1);
  }

  // Keydown function in input
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  }

  return (
    <div 
      className='w-[55%] h-[70vh] bg-white shadow-gray_shadow relative rounded-t-lg overflow-hidden'
    >

      {/* Header */}
      <div className='flex flex-row items-center justify-between p-5 bg-primary_color'>

        {/* User */}
        <div className='flex flex-row items-center'>
          <img src={USER} alt='' className='w-6 h-6 rounded-3xl border border-smoke_white' />
          <p className='text-white font-semibold text-[14px] ml-4'>{chatWindow.dialog.name}</p>
        </div>

        <div className='flex flex-row items-center'>

          {/* Video Call */}
          <img 
            src={VIDEO} 
            alt='' 
            className='w-[18px] h-4 mr-5 hover:cursor-pointer hover:opacity-70' 
            onClick={initiateVideoCall}
          />

          {/* Audio Call */}
          {/* <img src={GREEN_PHONE} alt='' className='w-3.5 h-3.5 mr-5 hover:cursor-pointer hover:opacity-70' /> */}

          {/* End chat */}
          <div 
            className='w-[72px] h-[22px] flex items-center justify-center bg-white rounded-lg hover:cursor-pointer hover:opacity-80'
            onClick={endChat}
          >
            <p className='font-normal text-[12px] text-black_scale_3'>End Chat</p>
          </div>

        </div>
      </div>

      {/* Messages */}
      {!showMessages && (
        <div className='absolute top-[65px] inset-x-0 bottom-[70px] bg-white z-20 flex items-center justify-center'>
          <CircularProgress color='success' />
        </div>
      )}
      <div className='h-[calc(70vh-150px)] overflow-y-auto thin-scroll px-5 pt-2 flex flex-col'>
        {messages.map((msg, index) => (
          <Fragment key={msg._id}>
            {((index !== 0 && 
            (moment(msg.created_at).format("DD-MM-YYYY") !== moment(messages[index - 1].created_at).format("DD-MM-YYYY"))) || index === 0) && (
              moment(msg.created_at).isSame(today, 'date') ? (
                <p className='font-normal text-[10px] text-center my-2.5 text-near_gray'>Today</p>
              ) : moment(msg.created_at).isSame(yesterday, 'date') ? (
                <p className='font-normal text-[10px] text-center my-2.5 text-near_gray'>Yesterday</p>
              ) : (
                <p className='font-normal text-[10px] text-center my-2.5 text-near_gray'>{moment(msg.created_at).format("DD MMMM YYYY")}</p>
              )
            )}
            <MessageSnippet
              msg={msg}
              received={msg.sender_id === chatWindow.opponentId} 
              nextMsg={index === messages.length - 1 ? null : messages[index + 1]} 
              prevMsg={index === 0 ? null : messages[index-1]}
              messageUserName={msg.sender_id === chatWindow.opponentId ? chatWindow.dialog.name : 'You'}
            />
          </Fragment>
        ))}
        <div ref={msgEndRef} />
      </div>

      <div className='absolute bottom-0 inset-x-0 px-5 bg-white py-3 flex flex-row items-center shadow-gray_shadow'>

        {/* Message input */}
        <input
          className='h-[50px] min-h-[50px] max-h-[50px] rounded-xl w-full py-3 px-3 hide-scroll outline-0 bg-gray_scale_1' 
          placeholder='Say something'  
          value={msg}
          onChange={e => setMsg(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        {/* File options */}
        <img 
          src={PIN} 
          className='w-3 h-4 absolute bottom-7 right-20 hover:cursor-pointer hover:opacity-70' 
          alt='' 
          onClick={imageOptionSelect}
        />

        {/* File input */}
        <input type='file' accept="image/png, image/jpeg, image/jpg" ref={fileInputRef} className='hidden' onChange={addImage} />

        {/* Send */}
        <div 
          className='w-10 h-10 rounded-[40px] bg-primary_color flex items-center justify-center ml-2 hover:cursor-pointer hover:opacity-70'
          onClick={sendMessage}
        >
          <img src={SEND} alt='' className='w-[18px] h-[18.5px]' />
        </div>

      </div>

    </div>
  );
}

export default ChatWindow;