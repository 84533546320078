import { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Bookings from '../../components/Bookings/Bookings.component';
import { setOngoingList } from '../../redux/bookingListSlice';
import { axios_instance } from '../../utils/axios.utils';
import { getCookie } from '../../utils/Cookie';
import AuthContext from '../../utils/AuthContext';

const Home = () => {
  const dispatch = useDispatch();
  const { authStatus } = useContext(AuthContext);

  useEffect(()=>{
    // fetch bookingList
    const authToken = getCookie('@authToken');
    axios_instance.get('service-provider/booking', {
      headers: { Authorization: `Bearer ${authToken}` }
    }).then(res => {
        dispatch(setOngoingList(res.data.data));
      }
    )
  },[dispatch]);

  // Request camera permission
  const requestCameraPermission = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(function(stream) {
        // User granted permission
        // You can choose to do something here if needed
        stream.getTracks().forEach(track => {
          track.stop();
        });
      })
      .catch(function(error) {
        // User denied permission or an error occurred
      });
  }

  useEffect(() => {
    if(authStatus === 'loggedIn') {
      // Check camera permission
      navigator.permissions.query({ name: 'camera' })
        .then(function(permissionStatus) {
          if (permissionStatus.state === 'granted') {
            // Camera permission has been granted
          } else if (permissionStatus.state === 'prompt') {
            // Camera permission is not granted but can be requested
            requestCameraPermission();
          } else {
            // Camera permission has not been granted
            requestCameraPermission();
          }
        })
        .catch(function(error) {
          requestCameraPermission();
        });
    }
  }, [authStatus]);

  return (
    <div className='p-20 min-h-[calc(100vh-60px)] pt-[100px] bg-smoke_white'>

      {/* Bookings */}
      <p className='text-[30px] text-black_scale_1 font-medium mt-5 mb-4'>Bookings</p>
      <Bookings />

    </div>
  );
}

export default Home;