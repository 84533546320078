export const BOOKINGS_DATA = [
  {
    id: 'Bookings_0',
    userName: 'Samual Jackson',
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    age: 57,
    motion: 'Normal',
    appetite: 'Normal',
    date: '22-11-2022'
  },
  {
    id: 'Bookings_1',
    userName: 'Samual Jackson',
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    age: 57,
    motion: 'Normal',
    appetite: 'Normal',
    date: '22-11-2022'
  },
  {
    id: 'Bookings_2',
    userName: 'Samual Jackson',
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    age: 57,
    motion: 'Normal',
    appetite: 'Normal',
    date: '22-11-2022'
  },
  {
    id: 'Bookings_3',
    userName: 'Samual Jackson',
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    age: 57,
    motion: 'Normal',
    appetite: 'Normal',
    date: '22-11-2022'
  },
  {
    id: 'Bookings_4',
    userName: 'Samual Jackson',
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    age: 57,
    motion: 'Normal',
    appetite: 'Normal',
    date: '22-11-2022'
  }
];