import { useContext, useState } from 'react';
import * as QB from 'quickblox/quickblox';
import { LOGO_TEXT, LOGOUT, SHOPPING_BAG_BLACK } from '../../assets/Images';
import AuthContext from '../../utils/AuthContext';
import { deleteCookie } from '../../utils/Cookie';
import ModalDialog from '../ModalDialog/ModalDialog';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  const { setAuthStatus } = useContext(AuthContext);

  const [modalDialogOpen, setModalDialogOpen] = useState(false)
  const [isLoading_modalOkBtn, setIsLoading_modalOkBtn] = useState(false)

  // Logout user
  const logout = () => {
    setIsLoading_modalOkBtn(true)

    //Disconnecting from chat and destroying session on logout
    QB.chat.disconnect();
    QB.destroySession(error => {
      if(error) {
        //Error
      }
    });
    deleteCookie('@authToken');
    deleteCookie('@QBToken');
    localStorage.clear();
    setAuthStatus('loggedOut');

    setModalDialogOpen(false);
    setIsLoading_modalOkBtn(false)
  }

  return (
    <>
      <div className='h-[89px] w-[100vw] bg-white shadow-white_shadow flex justify-between items-center fixed z-50 px-20'>
        {/* left-side */}
        <div className='flex'>
          {/* Logo */}
          <Link to="/">
            <img 
              src={LOGO_TEXT.default} 
              alt='' 
              className="cursor-pointer"
            />
          </Link>
        </div>

        {/* right-side */}
        <div className='flex space-x-5'>
          {/* booking */}
          <div className='flex items-center hover:cursor-pointer hover:opacity-70' onClick={() => navigate('/')}  >
            <img src={SHOPPING_BAG_BLACK.default} alt='' />
            <p className='ml-2 text-[16px] text-black_scale_2 font-normal'>Booking</p>
          </div>
          {/* Logout */}
          <div className='flex items-center hover:cursor-pointer hover:opacity-70' onClick={() => setModalDialogOpen(true)}>
            <img src={LOGOUT.default} alt='' />
            <p className='ml-2 text-[16px] text-black_scale_2 font-normal'>Logout</p>
          </div>
        </div>
      </div>

      {/* logout-modal-dialog */}
      <ModalDialog
        description="Are you sure you want to log out?"
        text_ok="LOGOUT"
        text_cancel="CANCEL"
        isOpen={modalDialogOpen}
        onClick_ok={logout}
        onClick_cancel={()=>setModalDialogOpen(false)}
        isLoading_okBtn={isLoading_modalOkBtn}
      />
    </>
  );
}

export default Navbar;