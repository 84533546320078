import { CircularProgress } from "@mui/material";

const CustomButton = ({ title, disabled, isLoading, onClick = () => {}, buttonClassNames, titleClassNames, leftChildren }) => {
  const onButtonPress = () => {
    if(!disabled && !isLoading) {
      onClick();
    }
  }

  return (
    <button 
      onClick={onButtonPress} 
      className={`
        h-[52px] w-[400px] bg-primary_color flex items-center justify-center rounded-lg 
        cursor-pointer ${disabled ? 'cursor-default opacity-30' : 'hover:opacity-70'}
        ${buttonClassNames}
      `}
    >
      {leftChildren}
      {isLoading 
        ? <CircularProgress size={20} style={{color: "white"}} />
        : <p className={`text-white text-[16px] font-bold text-center ${titleClassNames}`}>{title}</p>
      }
    </button>
  );
}

export default CustomButton;