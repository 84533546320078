import { CircularProgress } from '@mui/material'
import Modal from '@mui/material/Modal'

const ModalDialog = ({ isOpen, description, text_ok, text_cancel, onClick_ok, onClick_cancel, isLoading_okBtn }) => (
  <Modal
    open={isOpen}
    onClose={onClick_cancel}
  >
    <div 
      className='bg-white rounded-[12px] absolute left-[50%] top-[40%] translate-x-[-50%] 
        translate-y-[-50%] px-[70px] py-[50px] outline-none'
    >
      <p>{description}</p>
      <div className='flex items-center justify-center mt-[20px]'>
        <p className='text-light_black mr-[30px] cursor-pointer' onClick={onClick_cancel}>{text_cancel}</p>
        <p className='text-primary_color cursor-pointer flex items-center' onClick={onClick_ok}>
          {isLoading_okBtn ? <CircularProgress size={16} style={{color: "green"}} /> : text_ok}
        </p>
      </div>
    </div>
  </Modal>
)

export default ModalDialog