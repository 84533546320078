import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as QB from 'quickblox/quickblox';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import BookingCard from '../../components/Cards/BookingCard.component';
import ChatWindow from '../../components/ChatWindow/ChatWindow.component';
import { CHAT_BANNER } from '../../assets/Images';
import { Modal } from '@mui/material';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { axios_instance } from '../../utils/axios.utils';
import Autocomplete from '@mui/material/Autocomplete';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import { getCookie } from '../../utils/Cookie';

const Chat = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const bookingId = location?.state?.bookingData?.id

  const [chatWindow, setChatWindow] = useState({ show: false, dialog: null, opponentId: null, userId: null });
  const chatConnected = useSelector(state => state.chat.chatConnected);
  const authToken = getCookie("@authToken");
  const [bookingCardData, setBookingCardData] = useState({})
  const [dateTimeValues, setDateTimeValues] = useState(dayjs(new Date()))
  const [doctorsData, setDoctorsData] = useState([])
  const [onChangeDoctorName, setOnChangeDoctorName] = useState('')
  const [selectedDoctorData, setSelectedDoctorData] = useState()

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isLoading_confirmModalSubmit, setIsLoading_confirmModalSubmit] = useState(false)
  const [isLoading_cancelModalBtn, setIsLoading_cancelModalBtn] = useState(false)
  const [price, setPrice] = useState(0);

  //Create chat dialog
  const createChatDialog = () => {
    let params = {
      type: 3,
      occupants_ids: [location.state.bookingData?.user_external?.uuid]
    }
    QB.chat.dialog.create(params, (error, dialog) => {
      if(error) {
        // Error creating dialog
        toast.error('Chat dialog creation error');
      } else {
        // Dialog created
        let user = JSON.parse(localStorage.getItem("@QBChatUser"));
        setChatWindow({ show: true, dialog, userId: user?.user_id, opponentId: location.state.bookingData?.user_external?.uuid });
      }
    });
  }

  const handleConfirmModalOpen = () => {
    setIsConfirmModalOpen(true)
  }
  const handleConfirmModalClose = () => {
    setOnChangeDoctorName('')
    setSelectedDoctorData(null)
    setIsConfirmModalOpen(false)
  }

  const handleCancelModalOpen = () => {
    setIsCancelModalOpen(true)
  }
  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false)
  }

  const fetchBookingData = async () => {
    await axios_instance.get(`service-provider/booking-details/${bookingId}`, {
      headers: { Authorization: `Bearer ${authToken}` }
    }).then(res => {
      if(res?.data?.status === 'ok'){
        setBookingCardData(res.data.data)
      }
    }).catch(err => {
      
    })
  }

  const searchDoctor = async (doctorName) => {
    await axios_instance.post('service-provider/search-doctors', {
      name: doctorName
    }, {
      headers: { Authorization: `Bearer ${authToken}` }
    }).then(res => {
      if(res.data.status === 'ok'){
        setSelectedDoctorData(res.data.data[0]);
      }
    }).catch(err => {
      
    })
  }
  
  const confirmBooking = async () => {
    setIsLoading_confirmModalSubmit(true);
    await axios_instance.post('service-provider/confirm-booking', {
      "booking_id": bookingId,
      "doctor_id": selectedDoctorData?.doctor_profile?.doctor_id,
      "datetime": new Date(dateTimeValues),
      "price": price
    }, {
      headers: { Authorization: `Bearer ${authToken}` }
    }).then(res => {
      if(res.data.status === 'ok'){
        fetchBookingData();
        handleConfirmModalClose();
        setIsLoading_confirmModalSubmit(false);
        setSelectedDoctorData(null);
        navigate(-1);
      }
    }).catch(err => {
      setIsLoading_confirmModalSubmit(false)
    })
  }
  
  const cancelBooking = async () => {
    setIsLoading_cancelModalBtn(true)
    await axios_instance.post(`service-provider/cancel-booking/${bookingId}`, null, {
      headers: { Authorization: `Bearer ${authToken}` }
    }).then(res => {
      if(res.data.status === 'ok'){
        fetchBookingData();
        handleCancelModalClose();
        setIsLoading_cancelModalBtn(false);
        navigate(-1);
      }
    }).catch(err => {
      setIsLoading_cancelModalBtn(false)
    })
  }

  const handleBookingConfirmSubmit = (e) => {
    e.preventDefault();
    selectedDoctorData && confirmBooking();
  }
  
  useEffect(()=>{
    fetchBookingData();
    // eslint-disable-next-line
  },[bookingId])

  useEffect(() => {
    if(chatConnected) {
      createChatDialog();
    }
    // eslint-disable-next-line
  }, [chatConnected]);

  useEffect(()=>{
    axios_instance.post('service-provider/search-doctors', null, {
      headers: { Authorization: `Bearer ${authToken}` }
    }).then(res => {
      if(res.data.status === 'ok'){
        setDoctorsData(res?.data?.data)
      }
    }).catch(err => {
      
    })
    // eslint-disable-next-line
  },[])
  
  useEffect(() => {
    if(!!onChangeDoctorName) {
      searchDoctor(onChangeDoctorName)
    } else{
      setSelectedDoctorData(null)
    }
    // eslint-disable-next-line
  }, [onChangeDoctorName]);

  return (
    <>
      <div className='p-20 min-h-[calc(100vh-60px)] pt-[100px] bg-smoke_white'>
        {chatConnected  ? (
          <div className='flex mt-10 justify-between'>
            <div className='w-[40%] flex flex-col items-center'>

              {/* Booking card */}
              {Object.keys(bookingCardData).length ? (
                <BookingCard 
                  data={bookingCardData} 
                  type="chat" 
                  containerClassNames='!w-full !bg-white !border-0 !shadow-white_shadow'
                  handleConfirmModalOpen_chat={handleConfirmModalOpen} 
                  handleCancelModalOpen_chat={handleCancelModalOpen}
                />
              ) : null}

              {/* Banner */}
              <img src={CHAT_BANNER} alt='' className='h-[370px] mt-10' />

            </div>

            {/* Chat window */}
            {chatWindow.show && <ChatWindow chatWindow={chatWindow} setChatWindow={setChatWindow} />}

          </div>
        ) : (
          <div className='absolute inset-0 flex justify-center items-center'>
            <CircularProgress color='success' />
          </div>
        )}
      </div>

      {/* confirmBooking-modal-dialog */}
      <Modal
          open={isConfirmModalOpen}
          onClose={handleConfirmModalClose}
        >
        <div 
          className='bg-white rounded-[12px] absolute left-[50%] top-[40%] translate-x-[-50%] 
            translate-y-[-50%] px-[70px] py-[30px] outline-none'
        >
          <form type='submit' onSubmit={handleBookingConfirmSubmit}>
            <div className='flex flex-col space-y-3' >
              <p className='font-bold'>Title</p>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    label="Date & Time"
                    value={dateTimeValues}
                    inputFormat="DD-MM-YYYY hh:mm A"
                    minDate={dayjs(new Date())}
                    onChange={(newValue) => {
                      setDateTimeValues(newValue);
                    }}
                    renderInput={(params) => <TextField required size='small' {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div>
                <Autocomplete
                  disablePortal
                  size="small"
                  options={
                    (doctorsData.filter(item => item.doctor_profile.type === 'senior'))
                      .map((option) => option.doctor_profile.name)
                  }
                  noOptionsText='No options'
                  onChange={(event, value) => setOnChangeDoctorName(value)}
                  renderInput={(params) => <TextField required {...params} label="Doctor" />}
                />
              </div>

              <div>
                <TextField
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                  size='small'
                  defaultValue={'0'}
                  placeholder='price'
                />
              </div>

              <div className='flex justify-center space-x-3' >
                <CustomButton 
                  title="Cancel" 
                  buttonClassNames='!h-[35px] !w-full !bg-[#0000]'
                  titleClassNames='!font-semibold !text-[14px] !text-light_black'
                  onClick={handleConfirmModalClose} 
                  />
                <CustomButton 
                  title="Confirm" 
                  buttonClassNames='!h-[35px] !w-full !bg-primary_color'
                  titleClassNames='!font-semibold !text-[14px] !text-white'
                  isLoading={isLoading_confirmModalSubmit}
                  disabled={!selectedDoctorData}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {/* cancelBooking-modal-dialog */}
      <ModalDialog
        description="Do want to cancel booking?"
        text_ok="YES"
        text_cancel="NO"
        isOpen={isCancelModalOpen}
        onClick_ok={cancelBooking}
        onClick_cancel={handleCancelModalClose}
        isLoading_okBtn={isLoading_cancelModalBtn}
      />
    </>
  );
}

export default Chat;