import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  callNotification: {
    show: false,
    session: null,
    type: null
  },
  chatConnected: false
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setUser(state, { payload }) {
      state.user = payload;
    },
    setCallNotification(state, { payload }) {
      state.callNotification = payload
    },
    setChatConnected(state, { payload }) {
      state.chatConnected = payload
    }
  }
});

export const { setUser, setCallNotification, setChatConnected } = chatSlice.actions;

export default chatSlice.reducer;